
/*
|--------------------------------------------------------------------------
| Dependency Definitions
|--------------------------------------------------------------------------
*/

require('../bootstrap');

import Vue from 'vue';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);



/*
|--------------------------------------------------------------------------
| Global Components
|--------------------------------------------------------------------------
*/

Vue.component('application', require('./components/Application').default);



/*
|--------------------------------------------------------------------------
| Application
|--------------------------------------------------------------------------
*/

let app = new Vue({
  el: '#app'
});
