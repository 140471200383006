<script>

  /*
  |--------------------------------------------------------------------------
  | Dependency Definitions
  |--------------------------------------------------------------------------
  */

  import axios from 'axios';

  import Swal from 'sweetalert2';

  import { validationMixin } from "vuelidate";
  import { email, required, minValue, maxValue, minLength, maxLength, integer, sameAs } from "vuelidate/lib/validators";



  /*
  |--------------------------------------------------------------------------
  | Component Implementation
  |--------------------------------------------------------------------------
  */

  export default {
    name: 'application',

    mixins: [validationMixin],

    created() {
    },

    data() {
      return {
        firstName: null,
        lastName: null,
        personalNo: null,
        address: null,
        postalCode: null,
        city: null,
        phoneNo: null,
        email: null,

        termsAccepted: false,

        sending: false
      }
    },

    validations: {
      firstName: {
        required,
        maxLength: maxLength(255),
      },

      lastName: {
        required,
        maxLength: maxLength(255),
      },

      personalNo: {
        required,
        minLength: minLength(10),
        maxLength: maxLength(11),
      },

      address: {
        required,
        maxLength: maxLength(255),
      },

      postalCode: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(8),
      },

      city: {
        required,
        maxLength: maxLength(255),
      },

      phoneNo: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(16)
      },

      email: {
        required,
        email
      },

      termsAccepted: {
        sameAs: sameAs(() => true)
      }
    },

    computed: {
    },

    methods: {
      validateState(name) {
        const { $dirty, $error } = this.$v[name];

        return $dirty ? !$error : null;
      },

      createApplication() {
        if (!this.sending) {
          var self = this;

          this.$v.$touch();

          if (!this.$v.$invalid) {
            this.sending = true;

            axios.post('/api/v1/create_application', {
              first_name: this.firstName,
              last_name: this.lastName,
              personal_no: this.personalNo,
              address: this.address,
              postal_code: this.postalCode,
              city: this.city,
              phone_no: this.phoneNo,
              email: this.email,
              terms_accepted: this.termsAccepted
            })
            .then(function (response) {
              Swal.fire({
                icon: 'success',
                title: 'Tack för din ansökan!',
                text: 'Vi har skickat en bekräftelse med e-post.',
                showConfirmButton: false,
                timer: 5000,
                willClose: () => {
                  self.firstName = null;
                  self.lastName = null;
                  self.personalNo = null;
                  self.address = null;
                  self.postalCode = null;
                  self.city = null;
                  self.phoneNo = null;
                  self.email = null;
                  self.termsAccepted = false;

                  self.$v.$reset();

                  setTimeout(function() {
                    self.$v.$reset();
                    self.sending = false;
                  }, 100);
                }
              });
            })
            .catch(function (error) {
              console.log("Error creating application: ", error);

              self.sending = false;
            });
          }
        }
      },
    }
  };
</script>

<template>
    <b-card title="Registrera ansökan om köplats" class="w-100 p-5">
      <b-card-text>
        <b-row>
          <b-col>
            <b-form-input v-model="$v.firstName.$model" :state="validateState('firstName')" placeholder="Förnamn" trim></b-form-input>
          </b-col>

          <b-col>
            <b-form-input v-model="$v.lastName.$model" :state="validateState('lastName')" placeholder="Efternamn" trim></b-form-input>
          </b-col>
        </b-row>
      </b-card-text>

      <b-card-text>
        <b-form-input v-model="$v.personalNo.$model" :state="validateState('personalNo')" placeholder="Personnummer" trim></b-form-input>
      </b-card-text>

      <b-card-text>
        <b-form-input v-model="$v.address.$model" :state="validateState('address')" placeholder="Adress" trim></b-form-input>
      </b-card-text>

      <b-card-text>
        <b-row>
          <b-col>
            <b-form-input v-model="$v.postalCode.$model" :state="validateState('postalCode')" placeholder="Postnummer" trim></b-form-input>
          </b-col>

          <b-col>
            <b-form-input v-model="$v.city.$model" :state="validateState('city')" placeholder="Ort" trim></b-form-input>
          </b-col>
        </b-row>
      </b-card-text>

      <b-card-text>
        <b-row>
          <b-col>
            <b-form-input v-model="$v.phoneNo.$model" :state="validateState('phoneNo')" placeholder="Telefonnummer" trim></b-form-input>
          </b-col>

          <b-col>
            <b-form-input v-model="$v.email.$model" :state="validateState('email')" placeholder="E-post" trim></b-form-input>
          </b-col>
        </b-row>
      </b-card-text>

      <b-card-text>
        <b-form-checkbox v-model="$v.termsAccepted.$model" :state="validateState('termsAccepted')">
          Jag samtycker till att motta kommunikation i enlighet med <a href="https://wiklundska.firstvisiondev.se/integritetspolicy/" target="_blank" class="link-black">integritetspolicyn</a>.
        </b-form-checkbox>
      </b-card-text>

      <b-card-text>
        <b-button @click="createApplication" class="w-100" variant="primary">Skicka ansökan</b-button>
      </b-card-text>
    </b-card>
</template>

<style module lang="scss">

  /*
  |--------------------------------------------------------------------------
  | Application
  |--------------------------------------------------------------------------
  */

</style>
